const footerMenuList = [
  // {
  //   id: 1,
  //   title: "Marketplace",
  //   diffClass: "md:col-start-7",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "All NFTs",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "art",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "music",
  //     },
  //     {
  //       id: 4,
  //       href: "#",
  //       text: "domain names",
  //     },
  //     {
  //       id: 5,
  //       href: "#",
  //       text: "collections",
  //     },
  //     {
  //       id: 6,
  //       href: "#",
  //       text: "virtual world",
  //     },
  //   ],
  // },
  {
    id: 1,
    title: "Company",
    diffClass: "",
    list: [
      // {
      //   id: 1,
      //   href: "#",
      //   text: "Explore",
      // },
      {
        id: 2,
        href: "/about",
        text: "About Us",
      },
      {
        id: 5,
        href: "/faq",
        text: "FAQ",
      },
    ],
  },
  {
    id: 2,
    title: "Produk & Fitur",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/produkDanRetail",
        text: "Produk Dan Retail",
      },
      {
        id: 2,
        href: "/industrial",
        text: "Industrial",
      },
      {
        id: 3,
        href: "/tourGuide",
        text: "Tour Guide",
      },
      
    ],
  },
  {
    id: 3,
    title: "Menu",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Home",
      },
      {
        id: 2,
        href: "#",
        text: "produk & fitur",
      },
      {
        id: 3,
        href: "#",
        text: "Tending",
      },
      {
        id: 4,
        href: "#",
        text: "Top Kreator",
      },
      {
        id: 5,
        href: "#",
        text: "Showcase",
      },
      {
        id: 6,
        href: "#",
        text: "How It Works",
      },
      
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com",
    text: "world",
  },
  
];

export { footerMenuList, socialIcons };
