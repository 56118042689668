import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from "next/image";
import Logo from "../public/images/home/ARKAINDlogoBlack.png";
import WhiteLogo from "../public/images/home/ARKAINDlogo.png";
import sosmedCard from "../public/images/home/Sosmed Card-01.png";

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12"
            >
            {/* <div className={`col-span-full sm:col-span-3 md:col-span-4`}>
              <Image width={700} height={400} src={sosmedCard} />
            </div> */}

            <div className={`col-span-full sm:col-span-3 md:col-span-4`}
              style={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '20px',
                borderImage: 'linear-gradient(to right, #ff0000, #0000ff)',
                borderImageSlice: '20',
                padding: '10px'
              }}>
              <p>Contact Us</p>
              <div className="row">
                <div className="grid grid-cols-2">
                  <div className="col-6">
                    <Link href="#" className="mt-16 inline-block">
                      <Image
                        width={130}
                        height={28}
                        src="/images/home/ARKAINDlogoBlack.png"
                        className="max-h-7 dark:hidden"
                        alt="Augmee"
                      />
                    </Link>

                    <Link href="#" className=" mt-16 inline-block">
                      <Image
                        width={130}
                        height={28}
                        src="/images/home/ARKAINDlogo.png"
                        className="hidden max-h-7 dark:block mb-6"
                        alt="Augmee"
                      />
                    </Link>
                  </div>

                  <div className="col-6">
                    <Link
                      href={"https://augmee.id/"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          style={{ margin: "10px" }}
                          className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white "
                        >
                          <use xlinkHref={`/icons.svg#icon-world`}></use>
                        </svg>
                        <p style={{ alignSelf: "center", fontSize:"16px" }}>Augmee.id</p>
                      </div>
                    </Link>

                    <Link
                      href={"https://wa.me/6285642151557"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          style={{ margin: "10px" }}
                          className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                        </svg>
                        <p style={{ alignSelf: "center", fontSize:"13px" }}>+62 856-4215-1557</p>
                      </div>
                    </Link>

                    <Link
                      href={"https://www.instagram.com/augmeeid/"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                        <svg
                          style={{ margin: "10px" }}
                          className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                        >
                          <use xlinkHref={`/icons.svg#icon-instagram`}></use>
                        </svg>
                        <p style={{ alignSelf: "center", fontSize:"15px" }}>augmeeid</p>
                      </div>
                    </Link>

                    <Link
                      href={"https://id.linkedin.com/company/pt-bumi-teknik-semesta"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="group cursor-pointer"
                    >
                      <div style={{ display: "flex" }}>
                    <svg
                      style={{ margin: "10px" }}
                      className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.5 8.8v1.7a3.7 3.7 0 0 1 3.3-1.7c3.5 0 4.2 2.2 4.2 5v5.7h-3.2v-5c0-1.3-.2-2.8-2.1-2.8-1.9 0-2.2 1.3-2.2 2.6v5.2H9.3V8.8h3.2ZM7.2 6.1a1.6 1.6 0 0 1-2 1.6 1.6 1.6 0 0 1-1-2.2A1.6 1.6 0 0 1 6.6 5c.3.3.5.7.5 1.1Z"
                        clip-rule="evenodd"
                      />
                      <path d="M7.2 8.8H4v10.7h3.2V8.8Z" />
                    </svg>
                    <p style={{ alignSelf: "center", fontSize:"9px" }}>PT. BUMI TEKNIK SEMESTA</p>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <p className="dark:text-jacarta-300 mb-12">
                Create, sell and collect truly rare digital artworks. Powered by
                blockchain technology.
              </p> */}
            </div>

            {footerMenuList.map((single) => (
              <div
                className={`col-span-full sm:col-span-3 md:col-span-2 ${single.diffClass}`}
                key={single.id}
              >
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                  {single.title}
                </h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {single.list.map((item) => {
                    const { id, href, text } = item;
                    return (
                      <li key={id}>
                        <Link
                          href={href}
                          className="hover:text-accent dark:hover:text-white"
                        >
                          {text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>© {new Date().getFullYear()} AUGMEE — Made by</span>
              <Link
                href="https://themeforest.net/user/ib-themes"
                className="hover:text-accent dark:hover:text-white"
              >
                {" "}
                ENTWO
              </Link>
            </span>

            {/* <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link
                  href="/tarms"
                  className="hover:text-accent dark:hover:text-white"
                >
                  Privacy policy
                </Link>
              </li>
            </ul> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
