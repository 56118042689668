import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>Augmee</title>
        <link rel="icon" href="./favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Augmee",
  keyword:
    "Augmee, AR web, web AR, 3D model AR",
  desc: "AUGMEE merupakan web aplikasi yang dapat meningkatkan efisiensi, penjualan, dan transisi digital Anda dengan satu platform 3D. Anda dapat mengonversi, mengoptimalkan, berbagi, dan mempublikasikan aset 3D Anda sebagai showcase AR dari format apa pun ke perangkat apa pun",
};

export default Meta;
